/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import Popper from "popper.js/dist/umd/popper.js";
import noUiSlider from "nouislider";
import { Lithuanian } from "flatpickr/dist/l10n/lt.js";
import toastr from "toastr";

try {
    window.$ = window.jQuery = require("jquery");
    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
        }
    });
    window.Popper = Popper;
    window.noUiSlider = noUiSlider;

    require("select2/dist/js/select2.min.js");
    require("select2/dist/js/i18n/lt");
    require("sweetalert");

    require("easy-autocomplete");

    //Datetime picker
    require("flatpickr/dist/flatpickr.min.js");
    window.rangePlugin = require("flatpickr/dist/plugins/rangePlugin.js");
    flatpickr.localize(Lithuanian);

    require("jquery-ui/ui/widgets/slider.js");
    require("bootstrap");

    toastr.options = {
        timeOut: 5000,
        progressBar: true,
        positionClass: "toast-bottom-right"
    };
} catch (e) {}

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Sweetalert listener for livewire
window.addEventListener("swal", function(e) {
    swal(e.detail);
});

// toastr listener for livewire
window.addEventListener("toast-success", function(e) {
    toastr.success(e.detail);
});

window.addEventListener("toast-warning", function(e) {
    toastr.warning(e.detail);
});

window.addEventListener("toast-error", function(e) {
    toastr.error(e.detail);
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
