const utils = {
    overlayToggle: () => {
        if(document.body.classList.contains('has-overlay')) {
            document.body.classList.remove('has-overlay');
        } else {
            document.body.classList.add('has-overlay');
        }
    },

    overlayOpen: () => {
        document.body.classList.add('has-overlay');
    },

    overlayClose: () => {
        document.body.classList.remove('has-overlay');
    },

    sidebarToggle: () => {
        if(document.body.classList.contains('has-sidebar')) {
            document.body.classList.remove('has-sidebar');
        } else {
            document.body.classList.add('has-sidebar');
        }
    },

    sidebarOpen: () => {
        document.body.classList.add('has-sidebar');
    },

    sidebarClose: () => {
        document.body.classList.remove('has-sidebar');
    },
};

$(document).ready(function() {
    $("select.js-select").select2({
        language: "lt-LT",
        width: '100%',
        templateResult: window.formatOptionHelpText,
        placeholder: function(){
            $(this).data('placeholder');
        },
        matcher: window.modelMatcher
    });

    $('#parentCategorySelect').on('select2:select', function (e) {
        $('#categorySelect').parent().removeClass('d-none');
        $('#categorySelect').find('option').not(':first').remove();
        //$('#categorySelect').empty();

        $('#categorySelectThird').parent().addClass('d-none');
        $('#categorySelectThird').find('option').not(':first').remove();
        //$('#categorySelectThird').empty();

        $('#category').val('').trigger('change');

        let dataList = JSON.parse($('#parentCategorySelect option:selected').attr('data-list'));

        for (let [key, value] of Object.entries(dataList)) {
            let newOption = new Option(value.provider_title, value.id, false, false);
            $('#categorySelect').append(newOption)
        }
    });

    $('#categorySelect').on('select2:select', function (e) {
        let selectedOption = $(e.currentTarget).find('option:selected');
        if (!selectedOption.length) {
            return;
        }
        let dataList = JSON.parse($('#parentCategorySelect option:selected').attr('data-list'));

        $('#categorySelectThird').parent().addClass('d-none');
        $('#categorySelectThird').find('option').not(':first').remove();
        //$('#categorySelectThird').empty();

        for (let [key, value] of Object.entries(dataList)) {
            if (value.id == selectedOption.val()) {
                if (value.childs.length) {
                    $('#category').val('').trigger('change');
                    $('#categorySelectThird').parent().removeClass('d-none');
                    $('#categorySelectThird').find('option').not(':first').remove();
                    //$('#categorySelectThird').empty();
                    for (let [childKey, child] of Object.entries(value.childs)) {
                        let newOption = new Option(child.provider_title, child.id, false, false);
                        $('#categorySelectThird').append(newOption)
                    }
                    break;
                } else {
                    $('#category').val(selectedOption.val()).trigger('change');
                    break;
                }
            }
        }
        return;
    });

    $('#categorySelectThird').on('select2:select', function (e) {
        let selectedOption = $(e.currentTarget).find('option:selected');
        if (!selectedOption.length) {
            return;
        }
        $('#category').val(selectedOption.val()).trigger('change');
    });

    // $('#address_main').on('select2:select', function (e) {
    //     checkAndSetAddressID();
    // });

    $('#address_city').on('select2:select', function (e) {
        checkAndSetAddressID();
    });

    $('button.address-empty').on('click', function(e) {
        $(e.currentTarget).find("option:first").prop('selected', true).trigger('change');
        $(e.currentTarget).data('empty').forEach( function (empty) {
            if (empty) {
                $('#'+ empty).find('option:first').prop('selected', true).trigger('change');
            }
        });
        checkAndSetAddressID();
    });

    function checkAndSetAddressID()
    {
        let setIDTo = '';
        // Check all selects from first to last, and set value.
        let mainSelected = parseInt($('#address_main').val());
        if (mainSelected) {
            setIDTo = mainSelected;
        }

        let citySelected = parseInt($('#address_city').children('option:selected').val());
        if (citySelected) {
            setIDTo = citySelected;
        }

        $('#address').val(setIDTo);
    }

    $('a.service-gallery-image').imageLightbox({
        overlay: true,
        arrows: true,
        caption: true
    });

    $('a.task-gallery-image').imageLightbox({
        overlay: true,
        arrows: true,
        caption: true
    });

    $(".scrollTo").on('click', function(e) {
        // Close sidebar and overlay
        utils.sidebarClose();
        utils.overlayClose();

        e.preventDefault();
        if(this.href.replace(this.hash, '') === window.location.href.replace(this.hash, '')) {
            // Animate if user is in same page
            $('html, body').animate({
                scrollTop: $(this.hash).offset().top
                }, 300, function(){
            });
        } else {
            // Redirect if user is not in same page
            window.location.href = this.href;
        }
        window.location.hash = this.hash;
    });

    $('.dropdown-menu').on('click', function(e) {
        if($(this).hasClass('dropdown-menu-nc')) {
            e.stopPropagation();
        }
    });

    $('.only-number').keypress(function(event) {
        if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });

    $('[data-toggle="popover"]').popover();

    $('input, select, textarea').on('change', function() {
        if($(this).hasClass('is-invalid')) {
            $(this).removeClass('is-invalid');
        }
    });

    if($('body').hasClass('mobile-page')) {
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 70) {
                $('#header.top-mobile').addClass("fixed-top");
                $('body').css('padding-top', $('#header.top-mobile').outerHeight() + 'px');
            } else {
                $('#header.top-mobile').removeClass("fixed-top");
                $('body').css('padding-top', '0');
            }
        });
    }


    const sidebarToggle = document.getElementById('toggle-sidebar');
    if (sidebarToggle) {
        sidebarToggle.addEventListener('click', (e) => {
            e.preventDefault();
            utils.overlayToggle();
            utils.sidebarOpen();
        });
    }

    const sidebarClose = document.getElementById('sidebar-close');
    if (sidebarClose) {
        sidebarClose.addEventListener('click', (e) => {
            utils.overlayClose();
            utils.sidebarClose();
        });
    }

    const overlay = document.getElementById('overlay');
    if(overlay) {
        overlay.addEventListener('click', (e) => {
            utils.overlayClose();
            utils.sidebarClose();
        });
    }
});


/**
 * Update csrf token
 */
document.addEventListener('livewire:load', () => {
    setInterval(function(){
        updateCsrf();
    }, 300000);
});

function updateCsrf() {
    $.get('/update-csrf').done(function(data){
        $('[name="csrf-token"]').attr('content', data);
    });
}


window.formatOptionHelpText = function(item){
    if (!$(item.element).data('help-text')) {
        return item.text;
    }

    return $(
        '<div><div>' + item.text + '</div><small class="form-text">'
            + $(item.element).data('help-text')
            + '</small></div>'
    );
}


window.initSelect2  = function (elemnet) {
    $(elemnet).select2({
        language: "lt-LT",
        width: '100%',
        templateResult: window.formatOptionHelpText,
        placeholder: function(){
            $(this).data('placeholder');
        },
        matcher: window.modelMatcher
    });
}

window.initTypeSelect2 = function (selector) {
    $(selector).select2({
        minimumResultsForSearch: -1,
        language: "lt-LT",
        placeholder: function(){
            $(this).data('placeholder');
        }
    });
},

window.plural_nb = function(count)
{
    if (count % 10 === 1 && (count % 100 < 11 || count % 100 > 19)) {
        return 0;
    } else if (
        (count % 10 >= 2 && count % 10 <= 9) &&
        (count % 100 < 11 || count % 100 > 19)) {
        return 1;
    }
    return 2;
}

/**
 * Source: https://stackoverflow.com/a/36050326
 * Git: https://github.com/select2/select2/issues/3034#issuecomment-126451444
 */
window.modelMatcher = function (params, data) {
    data.parentText = data.parentText || "";

    // Always return the object if there is nothing to compare
    if ($.trim(params.term) === '') {
        return data;
    }

    // Do a recursive check for options with children
    if (data.children && data.children.length > 0) {
        // Clone the data object if there are children
        // This is required as we modify the object to remove any non-matches
        var match = $.extend(true, {}, data);

        // Check each child of the option
        for (var c = data.children.length - 1; c >= 0; c--) {
            var child = data.children[c];
            child.parentText += data.parentText + " " + data.text;

            var matches = modelMatcher(params, child);

            // If there wasn't a match, remove the object in the array
            if (matches == null) {
                match.children.splice(c, 1);
            }
        }

        // If any children matched, return the new object
        if (match.children.length > 0) {
            return match;
        }

        // If there were no matching children, check just the plain object
        return modelMatcher(params, match);
    }

    // If the typed-in term matches the text of this term, or the text from any
    // parent term, then it's a match.
    var original = (data.parentText + ' ' + data.text).toUpperCase();
    var term = params.term.toUpperCase();

    // Check if the text contains the term
    if (original.indexOf(term) > -1) {
        return data;
    }

    // If it doesn't contain the term, don't return anything
    return null;
}


